import utility from '@/common/utility'

export default class IdentityModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.identityGuid
      this.identityGuid = props.identityGuid
      this.identityName = props.identityName
      this.sortId = props.sortId
      this.createTime = props.createTime
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.identityGuid) this.identityGuid = ''
    if (!this.identityName) this.identityName = ''
    if (!this.sortId) this.sortId = 0
    if (!this.createTime) this.createTime = utility.getCurrentTime()
  }

  generatePrimaryKey () {
    this.identityGuid = utility.getUuid()
    this.resourceId = this.identityGuid
  }
}
