<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="identityModel"
    label-width="100px"
    >
    <el-form-item label="身份名称：" prop="identityName">
      <el-input
        type="text"
        v-model="identityModel.identityName">
      </el-input>
    </el-form-item>
    <el-form-item label="排序：" prop="sortId">
      <el-input-number
        v-model="identityModel.sortId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import IdentityModel from '@/model/IdentityModel'

export default {
  name: 'IdentityForm',
  props: {
    identityModel: {
      type: IdentityModel,
      default () {
        return new IdentityModel()
      }
    }
  },
  data () {
    return {
      rules: {
        identityName: [
          {
            required: true,
            message: '请输入身份名称',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    }
  }
}
</script>
